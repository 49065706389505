<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)">
        <v-row>
          <v-col
            v-if="!isNew && !page"
            cols="12"
          >
            <i class="fas fa-spin fa-spinner"></i>
          </v-col>
          <v-col
            v-else
            cols="11"
          >
            <material-card
              color="primary"
              :heading="pageTitle"
            >
              <v-card-text>
                <i
                  v-if="!isNew && !page"
                  class="fas fa-spin fa-spinner"
                ></i>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          :name="$t('forms.title').toLowerCase()"
                        >
                          <v-text-field
                            v-model="form.label"
                            :error-messages="errors"
                            outlined
                            :label="$t('forms.title')"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{}"
                          rules=""
                          :name="$t('forms.section').toLowerCase()"
                        >
                          <label>Rubrique</label>
                          <vue-select
                            v-model="form.section_id"
                            :options="sections"
                            :reduce="section => section.id"
                          >
                            <template v-slot:option="option">
                              <span v-html="option.option_label"></span>
                            </template>
                          </vue-select>
                          <!--<v-select
                            v-model="form.section_id"
                            :items="sections"
                            item-text="label"
                            item-value="id"
                            outlined
                            :error-messages="errors"
                            :label="$t('forms.section')"
                          />-->
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/pages/pages"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-card-text>
            </material-card>
            <ContentForm v-model="form.content">
              <div class="full-width d-flex justify-space-between align-center mt-4">
                <v-btn
                  color="gray"
                  to="/pages/pages"
                >
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  type="submit"
                  :color="processing ? 'gray' : 'success'"
                >
                  <v-icon
                    class="mr-3"
                    small
                    v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                  />
                  {{ $t('submit') }}
                </v-btn>
              </div>
            </ContentForm>
            <material-card
              class="mt-8"
              color="primary"
              :heading="$t('gallery')"
            >
              <v-card-text>
                <DropzoneComponent
                  v-model="form.pictures"
                  :label="$t('dropzone.label')"
                />
                <div class="full-width d-flex justify-space-between align-center mt-4">
                  <v-btn
                    color="gray"
                    to="/pages/pages"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-card-text>
            </material-card>
            <material-card
              class="mt-8"
              color="primary"
              :heading="$t('files')"
            >
              <v-card-text>
                <DropzoneComponent
                  v-model="form.documents"
                  :label="$t('dropzone.label')"
                  :pictures-only="false"
                  is-documents
                />
                <div class="mt-4 full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/news/articles"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-card-text>
            </material-card>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <ContentBlockToolbar @added="blockAddHandler" />
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import serverError from '../../mixins/serverError';
  import ContentForm from '../../components/Content/ContentForm';
  import ContentBlockToolbar from '../../components/ContentBlockToolbar';
  export default {
    name: 'PageForm',

    components: { ContentForm, ContentBlockToolbar },

    mixins: [serverError],

    data: () => ({
      form: {
        label: null,
        category_id: null,
        pictures: [],
        content: [],
        documents: [],
      },
      sectionList: [],
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        sections: 'page/getSectionsGrouped',
        page: 'page/getPage',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewPage') : this.$t('pageTitles.Editing') + this.page.label;
      },
      locale() {
        switch (process.env.VUE_APP_LOCALE) {
          case 'fr':
            return 'fr-FR';
          case 'en':
            return 'en-US';
          default:
            return 'en-US';
        }
      },
    },

    created() {
      this.$store.dispatch('page/fetchSectionsGrouped');
      if (!this.isNew) {
        this.$store.dispatch('page/fetchPage', this.$route.params.id)
          .then(() => {
            this.form = {
              ...this.page,
              content: (this.page.contents && this.page.contents[0] && this.page.contents[0].content) || [],
            };
          });
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const missingDocumentLabels = !this.form.documents || !this.form.documents.length
            ? false
            : this.form.documents.reduce((acc, item) => {
              if (!item.label) {
                acc = true;
              }
              return acc;
            }, false);
          if (missingDocumentLabels) {
            this.$toasted.error(this.$t('documents_missing_label'));
          } else {
            const { id, label, section_id, content } = this.form
            const pictures = this.form.pictures.map(picture => picture.id);
            const documents = this.form.documents.map(document => document.id);
            const payload = { id, label, section_id, content, pictures, documents };
            const action = this.isNew ? 'page/createPage' : 'page/updatePage';
            const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
            this.$store.dispatch(action, payload)
              .then(response => {
                const contentPayload = {
                  contentable_id: response.data.data.id,
                  contentable_type: 'App\\Models\\Page',
                  content: content,
                }
                this.$store.dispatch('page/createContent', contentPayload)
                  .then(() => {
                    this.$toasted.success(message);
                    if (this.isNew) {
                      this.$router.push('/pages/pages');
                    }
                  })
                  .catch(error => this.displayErrors(error));
              })
              .catch(error => this.displayErrors(error));
          }
        }
      },
      blockAddHandler(block) {
        this.form.content.push({
          type: block.component,
          content: null,
        });
        console.log('Block component', block);
      },
    },
  }
</script>
